export default {
  en: {
    yes: 'Yes',
    no: 'No',
    unanswered: 'Unanswered',
    notApplicable: 'Not Applicable',
    invalid: 'Invalid',
    notRequired: 'Not Required',
    requireYes: 'Require Yes',
    outOfScope: 'Out Of Scope',
    answer: 'Answer',
    answered: 'Answered',
    youAnswered: 'You Answered',
    editAnswer: 'Edit Answer',
    lastModified: 'Last Modified {0}',
    dateFormat: 'yyyy/MM/dd',
    past: 'When did your certification become valid?',
    expired: 'Expired',
    expiredOn: 'Expired on',
    expiresOn: 'Expires on',
    expiresOnDate: 'Yes (expires on {0})',
    policyPlaceholder: 'Select your required validity period',
    months: 'After {n} month | After {n} months',
    years: 'After {n} year | After {n} years',
    errors: {
      emptySelect: 'Requires one field to be selected.',
    },
  },
};
